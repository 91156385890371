import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/core/Layout"
import SEO from "../components/core/Seo"
import Hero from "../components/brewery/Hero"
import DarkBlock from "../components/brewery/DarkBlock"
import WhiteBlock from "../components/brewery/WhiteBlock"
import get from "lodash/get"
import PrivateRoute from "../router/PrivateRoute"

const LayoutStyled = styled(Layout)`
  margin: 0;
`

class BreweryPage extends Component {
  render() {
    const path = this.props.location.pathname
    const data = get(this, "props.data")
    const {
      page,
      hero,
      videoPlaceholderImg,
      backgroundImage,
      image1,
      image2,
      image3,
      image3mobile,
      image4,
      image4mobile
    } = data;

    return (
      <LayoutStyled>
        <SEO
          title={page.metaTitle}
          description={page.metaDescription}
          fullTitle={true}
          path={path}
        />
        <Hero hero={hero} />
        <DarkBlock
          backgroundImage={backgroundImage}
          videoPlaceholderImg={videoPlaceholderImg}
          image3={image3}
          image4={image4}
          image3mobile={image3mobile}
          image4mobile={image4mobile}
        />
        <WhiteBlock
          image1={image1}
          image2={image2}
          articles={page.blocks[0].slides}
        />
      </LayoutStyled>
    )
  }
}

function brewery(props) {
  return <PrivateRoute component={BreweryPage} {...props} />
}

export default brewery

export const pageQuery = graphql`
  query BreweryPageQuery {
    page: contentfulLandingPage(slug: { eq: "brewery" }) {
      id
      metaTitle
      metaDescription
      blocks {
        ... on ContentfulBlockCarousel {
          slides {
            ... on ContentfulBlockTextImage {
              title
              content {
                json
              }
              image {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    hero: file(relativePath: { eq: "brewery/hero-background.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    videoPlaceholderImg: file(
      relativePath: { eq: "brewery/video-thumbnail-opt.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1108) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    backgroundImage: file(
      relativePath: { eq: "brewery/videoblock-background.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image1: file(relativePath: { eq: "brewery/carousel-placeholder.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 916) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image2: file(relativePath: { eq: "brewery/support-opt.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 916) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image3: file(relativePath: { eq: "brewery/intro-img-left.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 990) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image4: file(relativePath: { eq: "brewery/intro-img-right.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 820) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image3mobile: file(
      relativePath: { eq: "brewery/intro-img-left-mobile.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 456) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image4mobile: file(
      relativePath: { eq: "brewery/intro-img-right-mobile.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 408) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
