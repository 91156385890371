import React from 'react';
import styled from 'styled-components';
import BlueLineImg from '../../resources/images/brewery/blue-line.svg';
import BrokenLineImg from '../../resources/images/brewery/broken-line.svg';
import PaddlesImg from '../../resources/images/brewery/paddles.svg';


const LinesContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const BlueLine = styled.img`
    width: 80px;
    padding-bottom: 25px;
`;
const BrokenLine = styled.img`
    height: 400px;
    padding-bottom: 25px;
`;
const Paddles = styled.img`
    height: 24px;
`;

const HeroLines = () => {
    return (
        <LinesContainer>
            <BlueLine src={BlueLineImg} />
            <BrokenLine src={BrokenLineImg} />
            <Paddles src={PaddlesImg} />
        </LinesContainer>

    )
}

export default HeroLines;
