import React from "react";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import { Row, Col, Container } from "styled-bootstrap-grid";
import BlueLinesImg from "../../resources/images/icons/waves.svg";
import { Fade } from "react-reveal";
import colors from "../../styles/colors";
import Carousel from "./Carousel";
import Img from "gatsby-image";

const WhiteBlockContainer = styled.div`
    width: 100%;
    background: ${colors.white};
    padding-bottom: 101px;
    @media(min-width: 768px){
        padding-bottom: 203px;
    }
`

const CarouselContainer = styled(Container)`
    margin: 171px auto 75px auto;
    position: relative;
    width: 100vw;
    max-width: 1140px;
    @media(max-width: 768px){
        padding: 83px 0 74px 0;
        margin-top: 0;
        margin-bottom: 0;
        max-width: none;
        margin-left: -50vw;
        left: 50%;
    }
`

const ContainerStyled = styled(Container)`
    padding-left: 33px;
    padding-right: 33px;
    width: auto;
    @media(max-width: 768px){
      max-width: none !important;
    }
`

const RowStyled = styled(Row)`
    margin-left: 0;
    margin-right: 0;
`

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
    position: relative;
`

const Title = styled.div`
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    font-size: 24px;
    letter-spacing: 1.43px;
    color: ${colors.navy};
    @media(min-width: 768px) {
        font-size: 42px;
        letter-spacing: 2.5px;
        padding-top: 129px;
    } 
`

const Text = styled.div`
    ${fonts.gilroyRegular};
    line-height: 21px;
    font-size: 15px;
    color: ${colors.grey};
    padding-top: 40px;
    max-width: 360px;
    padding-left: ${props => props.right ? "15%" : "0"};
    b {
      ${fonts.gilroyBold};
      color: ${colors.navy}
    }
    @media(max-width: 768px) {
        padding-left: 0;
        padding-top: 35px;
    }
`

const BlueLines = styled.img`
    width: 64px;
    height: auto;
    position: absolute;
    top: calc(30% - 7.5px);
    right: 15px;
    @media(min-width: 768px){
        width: 80px;
        right: -24px;
    }
`

const ImageStyled = styled(Img)`
    height: 100%;
    width: auto;
    object-fit: cover;
`

const WhiteBlockImage = styled.div`
    width: calc(100% - 46px);
    height: 400px;
    margin-bottom: 56px;
    overflow: hidden;
    margin-left: 0;
    @media(min-width: 768px) {
        height: 606px;
        width: auto;
        margin-bottom: 0;
        margin-left: 80px;
        padding-right: 15px;
        padding-left: 15px;
    }
`


const WhiteBlock = (props) => {
  return (
    <WhiteBlockContainer>
      <CarouselContainer>
        <Carousel image1={props.image1} articles={props.articles}/>
      </CarouselContainer>

      <ContainerStyled>
        <RowStyled>
          <ColStyled md={6} mdOrder={2} smOrder={1}>
            <Fade duration={1000} delay={250}>
              <WhiteBlockImage>
                <ImageStyled fluid={props.image2.childImageSharp.fluid} alt={"Image"}/>
              </WhiteBlockImage>
              <BlueLines src={BlueLinesImg}/>
            </Fade>
          </ColStyled>
          <ColStyled md={6} mdOrder={1} smOrder={2}>
            <Fade duration={400} delay={250}><Title>Support our voyage</Title></Fade>
            <Fade duration={400} delay={500}> <Text>Love our beers? Believe in the British coast? Then why not invest in
              Lost At Sea? We’re shortly launching our first round of investment giving you the chance to join the crew
              and hook yourself some shares within our great little brewery. Don’t miss the boat and <b>have a read of our
              prospectus and apply today!</b> </Text></Fade>
          </ColStyled>
        </RowStyled>
      </ContainerStyled>
    </WhiteBlockContainer>

  )
}

export default WhiteBlock

