import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import HorizontalLineImg from '../../resources/images/brewery/horizontal-broken-line.svg';
import LineImg from '../../resources/images/brewery/broken-line-mobile.svg';
import BlueLineImg from '../../resources/images/brewery/blue-line.svg';
import DecorationTitleImg1 from '../../resources/images/brewery/decoration-title-1.svg';
import DecorationTitleImg2 from '../../resources/images/brewery/decoration-title-2.svg';
import DecorationTitleImg3 from '../../resources/images/brewery/decoration-title-3.svg';
import DecorationTitleImg4 from '../../resources/images/brewery/decoration-title-4.svg';
import DecorationTitleImg5 from '../../resources/images/brewery/decoration-title-5.svg';
import DecorationTitleImg6 from '../../resources/images/brewery/decoration-title-6.svg';
import DecorationTitleImg7 from '../../resources/images/brewery/decoration-title-7.svg';
import DecorationTitleImg8 from '../../resources/images/brewery/decoration-title-8.svg';
import DecorationTitleImg9 from '../../resources/images/brewery/decoration-title-9.svg';
import DecorationTitleImg10 from '../../resources/images/brewery/decoration-title-10.svg';
import SplashOverlayImg from '../../resources/images/home/voyage-white-overlay.png';
import SplashOverlayImgMobile from '../../resources/images/brewery/splash-overlay-mobile.svg';
import { Fade, Zoom } from 'react-reveal';
import colors from "../../styles/colors";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import Play from "../../resources/images/ui/play.svg"
import Popup from "reactjs-popup";

const DarkBlockContainer = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: ${colors.darkNavyBackground};
`;

const IntroText = styled.div`
    color: ${colors.white}; 
    text-transform: uppercase;
    text-align: center;
    ${fonts.swissBlackExtended};
    max-width: 741px;
    margin: auto;
    padding-left: 10%;
    padding-right: 10%;
    letter-spacing: 1.25px;
    position: relative;
    z-index: 2;
    padding-top: 100px;
    line-height: 24px;
    font-size: 20px;
    @media(min-width: 768px){
        padding-top: 200px;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 1.75px;
    }
`;

const Description = styled.div`
    padding-top: ${props => props.left ? '115px' : '50px'};
    color: #9CA8B5;
    text-align: ${props => props.left ? 'left' : 'center'};
    font-size: 15px;
    line-height: 21px;
    ${fonts.gilroyRegular};
    max-width: 551px;
    padding-left: 10%;
    padding-right: 10%;
    margin: auto;
    position: relative;
    z-index: 2;
    padding-bottom: ${props => props.left ? '0px' : '200px'};
    @media(max-width: 1200px) {
        padding-top: ${props => props.left && '40px'};
    }
    @media(max-width: 991px) {
        padding-top: ${props => props.left && '0'};
        padding-left: 10%;
        padding-right: ${props => props.left ? '0px' : '10%'};
    }
    @media(max-width: 768px) {
        padding-bottom: 175px;
        padding-top: 40px;
        padding-left: 0;
    }
`;

const BackgroundImgLeft = styled(Img)`
    position: absolute !important;
    left: 0;
    top: 25px;
    height: 581px; 
    width: 100%;
    max-width: 495px;
    img {
      object-fit: contain!important;
    }
    @media(max-width: 890px) {
        display: none;
    }
`;

const BackgroundImgLeftMobile = styled(Img)`
    position: absolute !important;
    left: 0;
    top: 25px;
    height: 349px;
    width: 100%;
    max-width: 228px;
    img {
      object-fit: contain!important;
    }
    @media(min-width: 891px) {
        display: none;
    }
`;

const BackgroundImgRight = styled(Img)`
    height: 651px;
    width: 100%;
    max-width: 410px;
    position: absolute !important;
    right: 0;
    top: 75px;
    img {
      object-fit: contain!important;
    }
    @media(max-width: 890px) {
        display: none;
    }
`;

const BackgroundImgRightMobile = styled(Img)`
    height: 391px;
    width: 100%;
    max-width: 204px;
    position: absolute !important;
    right: 0;
    top: 180px;
    z-index: 1;
    img {
      object-fit: contain!important;
    }
    @media(min-width: 891px) {
        display: none;
    }
`;

const VideoBlockContainer = styled.div`
    width: 100vw;
    position: relative;
`;

const VideoContainer = styled(Container)`
    padding-left: 0;
    padding-right: 0;
    position: relative;
    z-index: ${props => props.popupOpen ? 999 : 10};
    padding-bottom: 90px;
    @media(min-width: 767px){
        padding-bottom: 300px;
    }
`;

const RowStyled = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    padding-left: 33px;
    padding-right: 33px;
`;

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
    position: relative;
    display: ${props => props.flex ? 'flex' : ''};
`;

const HorizontalBrokenLine = styled.img`
    padding-bottom: 33px;
    padding-top: 0;
    max-width: 100%;
    @media(max-width: 768px) {
        padding-top: 16px;
        padding-bottom: 0;
        margin-top: -160px;
    }
`;

const WhiteLineDesktop = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: calc(50% - 6px);
    left: -40%;
    @media(max-width: 991px) {
        left: -38%;
    }
    @media(max-width: 768px) {
        display: none;
    }
`;

const WhiteLineMobile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(min-width: 767px) {
        display: none;
    }
`;

const WhiteText = styled.div`
    color: white;
    font-size: 12px;
    letter-spacing: 2.77px;
    line-height: 12px;
    ${fonts.gilroyBold};
    text-transform: uppercase;
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    @media(max-width: 768px) {
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 35px;
    }
`;

const BlueLine = styled.img`
    width: 80px;
    padding-top: 25px;
    @media(max-width: 991px) {
        padding-top: 15px;
    }
`;

const DecorationTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    z-index: 9;
    bottom: 0;
`;

const TitleLetter = styled.img`
    height: ${props => props.small ? '26px' : '73px'};
    @media(max-width: 1162px){
        height: ${props => props.small ? '15px' : '53px'};
    }
    @media(max-width: 830px){
        height: ${props => props.small ? '7px' : '26px'};
    }
    @media(max-width: 420px){
        height: ${props => props.small ? '5px' : '20px'};
    }
`;

const SplashOverlay = styled.img`
    display: none;
    @media(min-width: 768px) {
        display: block;
        height: 231px;
        width: 100%;
        object-fit: cover;
        margin-top: 41px;
    }
`;

const SplashOverlayMobile = styled.img`
    width: 101%;
    object-fit: cover;
    margin-top: 22px;
    height: 141px;
    margin-bottom: -5px;
    @media(min-width: 768px) {
        display: none;
    }
`;

const BackgroundImageStyled = styled(BackgroundImage)`
    height: 729px;
    width: 100%;
    position: absolute !important;
    bottom: 0;
`;

const OverlayTop = styled.div`
    @media(min-width: 990px){
        position: absolute;
        height: 100px;
        width: 100%;
        background: -moz-linear-gradient(0deg, rgba(0, 9 , 20, 0) 8%, rgba(0, 9, 20, 100) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0, 9 , 20, 0) 8%, rgba(0, 9, 20, 100) 100%);
        background: linear-gradient(0deg, rgba(0, 9 , 20, 0) 8%, rgba(0, 9, 20, 100) 100%);
        top: 0;
        z-index: 0;
    }
`;

const OverlayBottom = styled.div`
    position: absolute;
    height: 100px;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 9, 20, 0), rgba(0, 9, 20, 1));
    bottom: 0;
    z-index: 8;
`;

const VideoPlaceholder = styled(Img)`
    width: 100%;
    height: 100%;
    @media(max-width: 768px) {
        max-width: 554px;
        height: 344px;
        margin: auto;
    }
`;

const VideoBlock = styled.div`
  height: 380px;
  max-width: 554px;
  width: inherit;
  position: relative;
  @media(min-width: 768px) {
      height: 663px;
    }
`;

const PlayButton = styled.button`
    background: transparent;
    border: none;
    outline: none;
    position: relative;
    cursor: pointer;
    img {
        width: 64px;
        z-index: 1
    }
    @media(min-width: 991px) {
        img {
            width: auto;
        }
    }
`

const VideoBackground = styled.div`
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

  }
`;

const Pulse = styled.span`
  height: 64px;
  width: 64px;
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  &:before {
    content: "";
    position: relative;
    display: block;
    width: 150%;
    height: 150%;
    box-sizing: border-box;
    margin-left: -25%;
    margin-top: -25%;
    border-radius: 100%;
    background-color: #9ddce2;
    animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
  @media (min-width: 991px) {
    width: 80px;
    height: 80px;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    &:before {
    content: "";
    position: relative;
    display: block;
    width: 150%;
    height: 150%;
    box-sizing: border-box;
    margin-left: -25%;
    margin-top: -25%;
    border-radius: 100%;
    background-color: #9ddce2;
    animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  }
`

const ButtonContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const contentStyle = {
    width: "90%",
    position: "absolute",
    border: "none",
    height: "auto",
    overflow: "hidden",
    background: "transparent",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }
  const contentStyleMobile = {
    width: "90%",
    position: "absolute",
    border: "none",
    height: "auto",
    overflow: "hidden",
    background: "transparent",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }
  const overlayStyle = {
    overflowY: "auto",
  }

const DarkBlock = ({ videoPlaceholderImg, backgroundImage, image3, image4, image3mobile, image4mobile }) => {
    const [popupOpen, setPopupOpen] = useState(false)
    const [width, setWidth] = useState()
    const voyageVideo = 'https://player.vimeo.com/video/486822697'

    const handleClose = () => {
        setPopupOpen(false)
    }

    const handleResize = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window.innerWidth)
        }
    }, [])

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("resize", handleResize)
        }
        return () => {
            window.removeEventListener("resize", handleResize)    
        }
    })

    return (
        <DarkBlockContainer>
            <div>
                <BackgroundImgLeft fluid={image3.childImageSharp.fluid} alt={"Lost At See"}/>
                <BackgroundImgLeftMobile fluid={image3mobile.childImageSharp.fluid} alt={"Lost At See"} />
                <IntroText>Everything we do is dedicated to the chosen few whose drive to go further means they’d rather be Lost At Sea than safe in harbour.</IntroText>
                <Description>They’re the ones who take risks and make things happen. They’re our kind of people. So we raise our glasses to life’s adventurers – this one’s for you. </Description>
                <BackgroundImgRight fluid={image4.childImageSharp.fluid} alt={"Lost At See"}/>
                <BackgroundImgRightMobile fluid={image4mobile.childImageSharp.fluid} alt={"Lost At See"}/>
            </div>
            <VideoBlockContainer>
                <VideoContainer popupOpen={popupOpen}>
                    <RowStyled>
                        <ColStyled md={6} flex>
                            <VideoBlock>
                                <VideoPlaceholder fluid={videoPlaceholderImg.childImageSharp.fluid}
                                                  alt={"Video Placeholder"}/>
                                <ButtonContainer>
                                    <PlayButton onClick={() => setPopupOpen(true)}>
                                        <img src={Play} alt="Play Video" />
                                        <Pulse />   
                                    </PlayButton>
                                </ButtonContainer>
                                <Popup
                                    contentStyle={width > 768 ? contentStyle : contentStyleMobile}
                                    overlayStyle={overlayStyle}
                                    open={popupOpen}
                                    closeOnDocumentClick
                                    onClose={handleClose}>
                                    <VideoBackground>
                                        <iframe src={`${voyageVideo}?autoplay=1&loop=1&controls=1&muted=${width > 768 ? "0" : "1"}`} title="Project Video" frameBorder="0" allow="fullscreen; autoplay" allowFullScreen={true}/>
                                    </VideoBackground>
                                </Popup>
                            </VideoBlock>
                        </ColStyled>
                        <ColStyled md={6}>
                            <WhiteLineMobile>
                                <HorizontalBrokenLine src={LineImg} />
                                <WhiteText>
                                    Where legends are born
                                    <BlueLine src={BlueLineImg} />
                                </WhiteText>
                            </WhiteLineMobile>
                            <Fade duration={1000} delay={250}>
                                <Description left >
                                    We're ridiculously proud of our Yorkshire based brewery, and love nothing more than
                                    to share what we do with others. Hopefully, you’ll all be welcome to come for a
                                    tour, enjoy a drink or three at our brewery bar and meet some the crew soon.
                                </Description>
                            </Fade>
                            <WhiteLineDesktop>
                                <Fade right duration={1000} delay={0}>
                                    <HorizontalBrokenLine src={HorizontalLineImg} />
                                </Fade>
                                <Fade right duration={1000} delay={250}>
                                    <WhiteText>
                                        Where legends are born
                                        <BlueLine src={BlueLineImg} />
                                    </WhiteText>
                                </Fade>
                            </WhiteLineDesktop>
                        </ColStyled>
                    </RowStyled>
                </VideoContainer>
                <DecorationTitle>
                    <Zoom cascade duration={3000}>
                        <TitleLetter src={DecorationTitleImg1} />
                        <TitleLetter src={DecorationTitleImg2} />
                        <TitleLetter src={DecorationTitleImg3} />
                        <TitleLetter src={DecorationTitleImg4} />
                        <TitleLetter src={DecorationTitleImg5} />
                        <TitleLetter src={DecorationTitleImg5} />
                        <TitleLetter src={DecorationTitleImg3} />
                        <TitleLetter small src={DecorationTitleImg6} />
                        <TitleLetter src={DecorationTitleImg7} />
                        <TitleLetter src={DecorationTitleImg8} />
                        <TitleLetter src={DecorationTitleImg5} />
                        <TitleLetter src={DecorationTitleImg9} />
                        <TitleLetter src={DecorationTitleImg10} />
                    </Zoom>
                </DecorationTitle>
                <OverlayTop/>
                <BackgroundImageStyled fluid={backgroundImage.childImageSharp.fluid} alt={'Background image'}/>
                <OverlayBottom/>
            </VideoBlockContainer>
            <SplashOverlay src={SplashOverlayImg} />
            <SplashOverlayMobile src={SplashOverlayImgMobile} />
        </DarkBlockContainer>
    )
}

export default DarkBlock;
