import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts'
import { Fade } from 'react-reveal';


const StyledTitle = styled.div`
    text-align: center;
`;
const OutlinedLine = styled.h1`
    text-transform: uppercase;
    font-size: 78px;
    letter-spacing: 6px;
    color: transparent;
    letter-spacing: 4px;
	-webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    ${fonts.swissBlackExtended}
    padding-bottom: 25px;
    padding-top: 300px;
    @media(max-width: 768px) {
        font-size: 47px;
        letter-spacing: 4px;
        -webkit-text-stroke-width: 1.5px;
        padding-top: 315px;
    }
`;

const Title = (props) => {
    const { title } = props;
    return (<StyledTitle>
        <Fade duration={750} delay={500}>
            <OutlinedLine>{title}</OutlinedLine>
        </Fade>
    </StyledTitle>);
}

export default Title;