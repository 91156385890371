import React from 'react';
import styled from 'styled-components';
import Title from '../core/OutlinedTitle';
import BackgroundImage from "gatsby-background-image";
import OverlayBottom from '../core/OverlayBottom';
import { Fade } from 'react-reveal';
import HeroLines from './HeroLines';

const BackgroundStyled = styled.div`
    position: relative;
    margin-top: -138px;
    text-align: center;
    height: 100%;
`;
const BackgroundImageStyled = styled(BackgroundImage)`
  width: 100vw;
  min-height: 100%;
  background-size: cover;
`;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  background: rgb(2 10 20 / 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

const TitleContainer = styled.div`
  @media (min-width: 768px) {
    padding-top: 110px;
  }
`


const BottomOverlay = styled.div`
    width: 100%;
    height: 500px;
    background: linear-gradient(to bottom, rgba(0, 9, 20, 0), rgba(0, 9, 20, 100)); 
`;

const LineContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
`

const HomeHero = ({ hero }) => {
  return (
    <BackgroundStyled>
      <BackgroundImageStyled fluid={hero.childImageSharp.fluid} critical={true} durationFadeIn={100}>
        <Overlay/>
        <TitleContainer>
         <Title title={"Brewery"}/>
        </TitleContainer>
        <LineContainer>
          <Fade bottom duration={750} delay={500}>
            <HeroLines/>
          </Fade>
        </LineContainer>
        <BottomOverlay />
      </BackgroundImageStyled>
    </BackgroundStyled>
  )
}

export default HomeHero
