import React, { Component } from "react"
import { Col, Row } from "styled-bootstrap-grid"
import styled from "styled-components"
import Slider from "react-slick"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import CarouselArrowUp from '../../resources/images/brewery/carousel-arrow-up.svg';
import CarouselArrowDown from '../../resources/images/brewery/carousel-arrow-down.svg';
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const RowStyled = styled(Row)`
  margin-left: 0;
  margin-right: 0;
  padding-left: 5.5px;
  padding-right: 5.5px;
  @media(min-width: 768px){
      padding: 0;
  }
`;

const ColStyled = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`;

const StyledSlider = styled(Slider)`
  width: 100%;
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  outline: none;
  border: 0;
`;

const ImageBlock = styled.div`
    max-height: 348px;
    overflow: hidden;
    @media(min-width: 768px){
        height: 458px;
        max-width: 458px;
        max-height: none;
    }
    img {
        width: 100%;
        object-fit: cover;
    }
`;

const ContentBlock = styled.div`
    opacity: ${props => props.current ? '1' : '0'};
    transition: .3s;
    @media(min-width: 768px){
        opacity: 1;
        padding-right: 15px;
        padding-left: 15px;
    }
`;

const CarouselNumber = styled.div`
    font-size: 12px;
    line-height: 14px;
    ${fonts.gilroyBold};
    letter-spacing: 2.77px;
    color: ${colors.navy};
    padding-bottom: 32px;
    padding-top: 59px;
    @media(min-width: 768px) {
        padding-bottom: 42px;
        padding-top: 80px;
    }
    span {
        color: ${colors.grey};
    }
`;

const Title = styled.div`
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 1.43px;
    color: ${colors.navy};
    padding-bottom: 31px;
    @media(min-width: 768px) {
        font-size: 42px;
        line-height: 50px;
        letter-spacing: 2.5px;
        padding-bottom: 39px;
    } 
`;

const Text = styled.div`
    ${fonts.gilroyRegular};
    line-height: 21px;
    font-size: 15px;
    color: ${colors.grey};
    @media(min-width: 768px) {
        max-width: 363px;
        margin-left: 80px;
    }
`;

const CarouselControllers = styled.div`
    position: absolute;
    right: 0;
    top: 200px;
    @media(max-width: 768px){
        display: none;
    }
`;

const Previous = styled.div`
    margin-bottom: 25px;
    img {
        height: 40px;
        cursor: pointer;
        width: 8px;
    }
`;

const Next = styled.div`
    margin-left: 6px;
    img {
        height: 40px;
        width: 8px;
        cursor: pointer;
    }
`;

class Carousel extends Component {
  state = {
    slideIndex: 0,
  }


  changeSlide(diff) {
    const slideIndex = this.state.slideIndex + diff

    this.slider.slickGoTo(slideIndex)
  }

  render() {
    const articles = this.props.articles

    let count = articles.length

    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      swipe: false,
      draggable: false,
      swipeToSlide: false,
      centerMode: false,
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerMode: true,
            centerPadding: "30px",
            swipe: true,
            draggable: true,
            swipeToSlide: true,
            infinite: false,
          },
        },
      ],
    }

    const {slideIndex} = this.state

    return (
      <React.Fragment>
        <StyledSlider {...settings} ref={slider => (this.slider = slider)} >
          {articles.map((article, index) => {
            return (
              <Slide key={index}>
                <RowStyled isFirst={index === 0}>
                  <ColStyled md={6} lg={6} xl={6}>
                    <ImageBlock>
                      <Img alt={article.title} fluid={article.image.fluid}/>
                    </ImageBlock>
                  </ColStyled>
                  <ColStyled md={6} lg={6} xl={6}>
                    <ContentBlock current={slideIndex === index}>
                        <CarouselNumber>{index + 1 < 9 ? `0${index + 1}` : index + 1}<span>------</span> 0{count}</CarouselNumber>
                        <Title>{article.title}</Title>
                        <Text>{documentToReactComponents(article.content.json)}</Text>
                    </ContentBlock>
                  </ColStyled>
                </RowStyled>
              </Slide>
            )
          })}
        </StyledSlider>
        <CarouselControllers>
          <Previous onClick={() => this.changeSlide(-1)}><img src={CarouselArrowUp} alt={"Previous Slide"}/></Previous>
          <Next onClick={() => this.changeSlide(1)}><img src={CarouselArrowDown} alt={"Next Slide"}/></Next>
        </CarouselControllers>
      </React.Fragment>
    )
  }
}

export default Carousel
